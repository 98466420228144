import pdfIcon from 'assets/svg/pdfIcon.svg';
import docIcon from 'assets/svg/docIcon.svg';
import xlsIcon from 'assets/svg/xlsIcon.svg';
import rtfIcon from 'assets/svg/rtfIcon.svg';
import imgIcon from 'assets/svg/imgIcon.png'

import isEmpty from './isEmpty';
import {
  LCL_MAX_DIMENSION_CM,
  LCL_MAX_DIMENSION_IN,
  LCL_MAX_VOLUME_CBM,
  LCL_MAX_VOLUME_CFT,
  LCL_MAX_WEIGHT_KG,
  LCL_MAX_WEIGHT_LB,
  VOLUME_UNITS,
  VOL_DIVISOR_CM_TO_CBM,
  VOL_DIVISOR_IN_TO_CFT
} from './constants';

// eslint-disable-next-line
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const POSTCODE_REGEX = /^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z][ ]?[0-9][A-Za-z][0-9]$/;

export const isValidEmail = (email) => {
  if (email && email !== null) {
    return EMAIL_REGEX.test(email);
  }
  return false;
};

export const isValidPassword = (value) => {
  let minCharBool = false;
  let numBool = false;
  let upperCaseBool = false;
  let lowerCaseBool = false;
  let specialCharBool = false;
  let spaceBool = false;

  // Specify errors

  // minimum character length
  if (!/.{8,}/.test(value)) {
    minCharBool = true;
  }

  // minimum one number
  if (!/(?=.*\d)/.test(value)) {
    numBool = true;
  }

  // minimum one lowecase
  if (!/(?=.*[a-z])/.test(value)) {
    lowerCaseBool = true;
  }

  // minimum one uppercase
  if (!/(?=.*[A-Z])/.test(value)) {
    upperCaseBool = true;
  }

  // minimum one special character
  if (!/(?=.*[^\da-zA-Z])/.test(value)) {
    specialCharBool = true;
  }

  // no spaces allowed
  if (value.split(' ').length > 1) {
    spaceBool = true;
  }

  if (
    minCharBool ||
    numBool ||
    lowerCaseBool ||
    upperCaseBool ||
    specialCharBool ||
    spaceBool
  ) {
    return false;
  } else {
    return true;
  }
};

export const getReservationType = (str) =>
  str.includes('umapped') ? str.substr(20) : str.substr(19);

export function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

/**
 * Formats and returns only the date in the format:
 *
 * ```
 * Jan 22, 2021
 * ```
 *
 * @param {string|number} date - the date string or seconds
 */
export function formatDate(date) {
  let time = '';
  if (date && date !== null && isValidDate(date)) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    time = Intl.DateTimeFormat('en-US', options).format(new Date(date));
  }
  return time;
}

/**
 * Formats and returns the date and Time in the format:
 *
 * ```
 * Jan 22, 2021
 * ```
 *
 * @param {string|number} date - the date string or seconds
 */
export function formatDateTime(date) {
  let time = '';
  if (date && date !== null && isValidDate(date)) {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short'
    };
    time = Intl.DateTimeFormat('en-US', options).format(new Date(date));
  }
  return time;
}

/**
 * Formats and returns the address in the format:
 *
 * ```
 * Abc Street, Suite 123,
 * City, State,
 * Country Zipcode
 * ```
 *
 * @param {} address - the date string or seconds
 */
export function addressToString(address) {
  // let result = '';
  // if (address) {
  //   if (address.streetNumber) {
  //     result += address.streetNumber + ' ';
  //   }
  //   if (address.streetAddress) {
  //     result += address.streetAddress + ', ';
  //   }
  //   if (address.suite) {
  //     result += 'Suite ' + address.suite + ', \n';
  //   }
  //   if (address.addressLocality) {
  //     result +=( address.addressLocality.label ? address.addressLocality.label : address.addressLocality) + ', ';
  //   }
  //   if (address.addressRegion) {
  //     result += (address.addressRegion.label ? address.addressRegion.label : address.addressRegion )+ ', \n';
  //   }
  //   if (address.addressCountry) {
  //     result += address.addressCountry.value + ' ';
  //   }
  //   if (address.postalCode) {
  //     result += address.postalCode;
  //   }
  // }
  let result = '';
  if (address) {
    if (address.streetNumber) {
      result += address.streetNumber + ' ';
    }
    if (address.street) {
      result += address.street + ', ';
    }
    if (address.suite) {
      result += 'Suite ' + address.suite + ', \n';
    }
    if (address.city) {
      result += address.city.name + ', ';
    }
    if (address.state) {
      result += address.state.name + ', \n';
    }
    if (address.country) {
      result += address.country.code + ' ';
    }
    if (address.postalCode) {
      result += address.postalCode;
    }
  }
  return result;
}

export const formatDateInput = (date) => {
  const dateArr = [];
  const chunk = date.substring(0, 4);

  if (chunk.length > 0) {
    dateArr.push(chunk);
  }

  const chunk1 = date.substring(4, 6);

  if (chunk1.length > 0) {
    dateArr.push(chunk1);
  }

  const chunk2 = date.substring(6, 8);

  if (chunk2.length > 0) {
    dateArr.push(chunk2);
  }

  if (dateArr && dateArr.length > 0) {
    return dateArr.join('-');
  }

  return '';
};

export const isEmptyString = (str) => {
  return !str || str.trim().length < 1;
};

export const maskCardNumber = (num) => {
  if (num && !isEmptyString(num)) {
    return `•••• •••• ${num}`;
  }
  return '';
};

export const maskPhoneNumber = (number) => {
  let mask = '***********';
  const prefix = number.slice(0, 6);
  const suffix = number.slice(-3);
  return prefix.concat(mask.concat(suffix));
};

export const maskEmail = (email) => {
  let mask = '***********';
  const prefix = email.slice(0, 3);
  const suffix = email.slice(-4);
  return prefix.concat(mask.concat(suffix));
};

export const getFileExtensionIcon = (file) => {
  const extension = file?.split('.')?.pop();
  let icon = null;
  if (extension?.toLowerCase() === 'pdf') {
    icon = pdfIcon;
  } else if (
    extension?.toLowerCase() === 'xls' ||
    extension?.toLowerCase() === 'xlsx'
  ) {
    icon = xlsIcon;
  }
  else if (
    extension?.toLowerCase() === 'jpg' ||
    extension?.toLowerCase() === 'jpeg' ||
    extension?.toLowerCase() === 'png'
  ) {
    return 'image';
  } else if (extension?.toLowerCase() === 'rtf') {
    icon = rtfIcon;
  } else {
    icon = docIcon;
  }
  return icon;
};

export const getCardIconFA = (card) => {
  if (card?.brand?.toLowerCase().startsWith('american')) {
    return { style: "fab", icon: 'cc-amex' };
  } else if (card?.brand?.toLowerCase().startsWith('master')) {
    return { style: "fab", icon: 'cc-mastercard' };
  } else if (card?.brand?.toLowerCase().startsWith('visa')) {
    return { style: "fab", icon: 'cc-visa' };
  } else if (card?.brand?.toLowerCase().startsWith('jcb')) {
    return { style: "fab", icon: 'cc-jcb' };
  } else if (card?.brand?.toLowerCase().startsWith('diner')) {
    return { style: "fab", icon: 'cc-diners' };
  } else if (card?.brand?.toLowerCase().startsWith('discover')) {
    return { style: "fab", icon: 'cc-discover' };
  } else if (card?.brand?.toLowerCase().startsWith('paypal')) {
    return { style: "fab", icon: 'paypal' };
  } else if (card?.brand?.toLowerCase().startsWith('bank')) {
    return { style: "fas", icon: 'university' };
  } else {
    return { style: "fas", icon: 'money-check-alt' };
  }
};
// export const isValidPortAddress = (address) => {
//   if (
//     !isEmpty(address) &&
//     !isEmpty(address.addressType) &&
//     !isEmpty(address.addressCountry) &&
//     !isEmpty(address.addressLocation)
//   ) {
//     return true;
//   } else {
//     return false;
//   }
// };
// export const isValidPortAddress = (address) => {
//   if (
//     !isEmpty(address) &&
//     // !isEmpty(address.address_city) &&
//     !isEmpty(address.address_country) &&
//     !isEmpty(address.address_state) &&
//     !isEmpty(address.address_port_code)
//   ) {
//     return true;
//   } else {
//     return false;
//   }
// };
export const isValidPortAddress = (address, required) => {
  if (
    isEmpty(address) ||
    // !isEmpty(address.address_city) &&
    isEmpty(address.address_country) ||
    isEmpty(address.address_state)
    // (required && isEmpty(address.address_state)) ||
    // (required && isEmpty(address.address_port_code))
  ) {
    return false;
  } else {
    return true;
  }
};


// Pallets Validation
export const isValidDimension = (dimension) => {
  if (dimension) {
    return (
      Number(dimension.length) > 0 &&
      Number(dimension.width) > 0 &&
      Number(dimension.height) > 0
    );
  }
  return false;
};

export const isValidDimensionSizes = (dimension) => {
  if (dimension) {
    const maxSize =
      dimension.unit === 'IN' ? LCL_MAX_DIMENSION_IN : LCL_MAX_DIMENSION_CM;
    return (
      Number(dimension.length) <= maxSize &&
      Number(dimension.width) <= maxSize &&
      Number(dimension.height) <= maxSize
    );
  }
  return false;
};

export const isValidVolumeSizes = (value) => {
  if (value) {
    const maxSize =
      value.unit === 'CFT' ? LCL_MAX_VOLUME_CFT : LCL_MAX_VOLUME_CBM;
    return Number(value.volume) <= maxSize;
  }
  return false;
};

export const isValidWeightSizes = (value) => {
  if (value) {
    const maxWeight =
      value.unit === 'LB' ? LCL_MAX_WEIGHT_LB : LCL_MAX_WEIGHT_KG;
    return Number(value.weight) <= maxWeight;
  }
  return false;
};

export const calcDimensionVolume = (dimension) => {
  if (dimension) {
    const totalUnit =
      dimension.unit === 'IN' ? VOLUME_UNITS.CFT : VOLUME_UNITS.CBM;
    const divisor =
      dimension.unit === 'IN' ? VOL_DIVISOR_IN_TO_CFT : VOL_DIVISOR_CM_TO_CBM;

    const total =
      (Number(dimension.length) *
        Number(dimension.width) *
        Number(dimension.height)) /
      divisor
    
    // console.log("fjbhj", volume)

    return {
      value: total,
      unit: totalUnit
    };
  }
  return null;
};


export const maxDateOfBirth= () => {
  const max16YearsAgo = new Date();
  max16YearsAgo.setFullYear(max16YearsAgo.getFullYear() - 16);
  return max16YearsAgo
}

export const isValidDateOfBirth = (date) => {
  const minBirthDate = new Date();
  minBirthDate.setFullYear(minBirthDate.getFullYear() - 16);
  const minBirthDateString = minBirthDate.toISOString().split('T')[0];
  if (date > minBirthDateString) {
    return false
  }
  return true
}

export const DOBErrorMsg = "Age can not be less than 16 years"