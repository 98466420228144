const hostname = window.location.hostname;

export function getBaseUrl() {
  const url = `${getServerUrl()}/kargoplex/api/`;
  return url;
}
export function getServerUrl() {
  if (hostname.includes('localhost')) {
    return modes.get('local');
  } else if (hostname.includes('test')) {
    return modes.get('test');
  } else if (hostname.includes('staging')) {
    return modes.get('staging');
  } else {
    return modes.get('production');
  }
}

export const modes = new Map([
  ['local', 'http://localhost:5000'],
  ['test', 'https://staging-server.kargoplex.com'],
  ['staging', 'https://staging-server.kargoplex.com'],
  ['production', 'https://server.kargoplex.com']
]);

// export function getBaseUrl() {
//   if (hostname.includes('localhost')) {
//   return modes.get('local');
//   } else if (hostname.includes('test')) {
//   return modes.get('test');
//   } else if (hostname.includes('staging')) {
//     return modes.get('staging');
//   } else {
//   return modes.get('production');
//   }
// }

// export const modes = new Map([
//   ['local', 'http://localhost:5000/kargoplex/api/'],
//   ['test', "https://staging-server.kargoplex.com/kargoplex/api/"],
//   ['staging', 'https://staging-server.kargoplex.com/kargoplex/api/'],
//   ['production', 'https://server.kargoplex.com/kargoplex/api/']
// ]);
