import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Select, { createFilter, components } from 'react-select';
// import makeAnimated from 'react-select/animated';
// import countries from 'util/countryList';
import {
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  Paper,
  TextField,
  Tooltip
} from '@material-ui/core';
import CountryFlag from 'components/CountryFlag';
import { Autocomplete } from '@material-ui/lab';
import ConstantValuesService from 'service/ConstantValuesService';
import ClearIcon from '@mui/icons-material/Clear';
import { countriesDataFromDatabase } from 'util/countryList';
const styles = (theme) => ({
  root: {
    width: '100%'
  },
  dropdown: {
    position: 'relative',
    width: '100%'
  },
  errorMsg: {
    color: '#f44336',
    fontSize: '12px',
    margin: '8px 14px 0'
  },
  errorBorder: {
    border: '1px solid red'
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
});

const filterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFrom: 'start'
};

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
      .toUpperCase()
      .replace(/./g, (char) =>
        String.fromCodePoint(char.charCodeAt(0) + 127397)
      )
    : isoCode;
}

// separate countries into groups
// export const groupedCountries = [
//   {
//     label: 'Suggested',
//     options: countries.filter((c) => c.suggested)
//   },
//   {
//     label: 'All Countries',
//     options: countries.filter((c) => !c.suggested)
//   }
// ];

const CustomPaper = (props) => {
  return <Paper elevation={8} {...props} />;
};

const CountrySelectNew = ({
  classes,
  value,
  defaultValue,
  useDefault,
  name,
  placeholder,
  showError,
  error,
  onChange,
  onBlur,
  closeMenuOnSelect,
  isMulti,
  isClearable,
  label,
  variant,
  margin,
  fullWidth,
  required,
  size,
  autoHighlight,
  openOnFocus,
  style,
  closeIcon,
  disabled,
  transportMode,
  ...rest
}) => {
  // const [countries, setCountries] = useState([])
  const [countries, setCountries] = useState([]);

  // const [groupedCountries, setGroupedCountries] = useState([])
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState(null);

  // const fetchData = async () => {
  //     ConstantValuesService.fetchCountryList(input).then(res => {
  //         if (res.error) {
  //             setCountries([])
  //             console.log(res.error)
  //         } else {
  //             // const array = countries.map((option) => {
  //             //     return {
  //             //         priority: option.suggested ? 'most popular' : '',
  //             //         ...option
  //             //     };
  //             // });
  //             // setGroupedCountries(array)
  //             setCountries(res)
  //         }
  //         setLoading(false)
  //     })
  // }

  // useEffect(() => {

  //     const array = countries.map((option) => {
  //         return {
  //             priority: option.suggested ? 'most popular' : '',
  //             ...option
  //         };
  //     });
  //     setGroupedCountries(array)

  // }, [countries])

  // useEffect(() => {
  //     if (input) {
  //         setLoading(true)
  //         const timeOut = setTimeout(() => {
  //             fetchData();
  //         }, 500)

  //         return () => {
  //             clearTimeout(timeOut)
  //         }
  //     } else {
  //         setCountries([])
  //         return
  //     }

  // }, [input])

  return (
    <div className={classes.root}>
      {/* <FormControl
                required={required}
                margin={margin}
                variant={variant}
                fullWidth={fullWidth}
                error={showError && error}> */}
      <InputLabel htmlFor={name}></InputLabel>
      <Autocomplete
        id="country-select"
        value={value}
        defaultValue={defaultValue}
        multiple={isMulti}
        limitTags={7}
        // noOptionsText="No match found"
        onChange={onChange}
        disabled={disabled}
        onBlur={onBlur}
        size={size}
        style={style}
        autoHighlight={autoHighlight}
        openOnFocus={openOnFocus}
        disableCloseOnSelect={!closeMenuOnSelect}
        disableClearable={!isClearable}
        // options={groupedCountries.sort(
        //     (a, b) => -a.priority.localeCompare(b.priority)
        // )}
        options={countriesDataFromDatabase}
        // http://localhost:4000/shipments/id?view_detail=true&view_detail_tab=payment
        closeIcon={<ClearIcon sx={{ width: '20px', height: '20px' }} />}
        // groupBy={(option) => option.priority}
        PaperComponent={CustomPaper}
        classes={{
          option: classes.option,
          paper: 'country-autocomplete'
          // popper: 'minw-250'
        }}
        // new things
        loading={input && loading} // new
        onInputChange={(event, newValue) => {
          setInput(newValue);
        }}
        onFocus={() => { }}
        popupIcon={false}
        clearOnBlur={true}
        noOptionsText={input ? 'No match found' : 'Search Country'}
        getOptionLabel={(option) => option.name}
        renderOption={(option) => (
          <React.Fragment>
            <span className="mr-2 d-flex">
              <CountryFlag countryCode={option.code} />
            </span>
            <span className="font-size-md">{option.name}</span>
          </React.Fragment>
        )}
        renderInput={(params) => {
          const inputProps = !isMulti
            ? {
              ...params.InputProps,
              startAdornment: value && (
                <span className="mx-1 d-flex">
                  <CountryFlag countryCode={value.code} />
                </span>
              )
            }
            : params.InputProps;
          return (
            <TextField
              {...params}
              name={name}
              label={label}
              variant={variant}
              InputProps={inputProps}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off' // disable autocomplete and autofill
              }}
            />
            // <TextField
            //     {...params}
            //     name={name}
            //     label={label}
            //     variant={variant}
            //     {(!isMulti && {
            //         InputProps: {
            //             ...params.InputProps,
            //             startAdornment: value && (
            //                 <span className="mx-1 d-flex">
            //                     <CountryFlag countryCode={value.code} />
            //                 </span>
            //             ),
            //         }
            //     })}
            //     inputProps={{
            //         ...params.inputProps,
            //         autoComplete: 'off' // disable autocomplete and autofill
            //     }}
            // />)
          );
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            console.log('Render Tags', value, option, getTagProps({ index }));
            return (
              <Tooltip
                hidden={!isMulti}
                arrow
                title={option.name}
                placement="right">
                <Chip
                  variant="outlined"
                  size="small"
                  style={{
                    background: 'rgba(163,163,163)'
                  }}
                  icon={
                    <span className="ml-2 d-flex">
                      <CountryFlag countryCode={option.code} />
                    </span>
                  }
                  label={option.name}
                  {...getTagProps({ index })}
                />
              </Tooltip>
            );
          })
        }
      />
      {showError && error && (
        <FormHelperText variant="outlined" error>
          {error}
        </FormHelperText>
      )}
      {/* </FormControl> */}
    </div>
  );
};

CountrySelectNew.defaultProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
  required: false,
  error: '',
  showError: false,
  closeMenuOnSelect: true,
  isMulti: true,
  name: 'Country *',
  label: 'Country *',
  placeholder: 'Country *',
  isClearable: true,
  fieldsValidCallback: () => { },
  size: 'small',
  autoHighlight: true,
  openOnFocus: true,
  useDefault: false,
  style: { width: '100%' },
  closeIcon: true,
  disabled: false,
  transportMode: ""
};

CountrySelectNew.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ]),
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  showError: PropTypes.bool,
  error: PropTypes.string,
  fieldsValidCallback: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
  useDefault: PropTypes.bool,
  closeIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  transportMode: PropTypes.string
};

export default compose(withStyles(styles))(CountrySelectNew);
