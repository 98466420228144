import React from 'react'
import Style from "./AmountCard.module.css"
import { getCurrencyIcon, numberToLocaleString } from 'util/Shipment/shipment'
const AmountInfoCard = ({ amount_info }) => {
    return (
        <div className="bg-serious-blue rounded d-flex justify-content-between p-2 p-md-4">
            <div className="p-2">
                <div>
                    <span className=" text-white-50 font-size-xs text-uppercase mb-2">
                        Amount Paid Out
                    </span>
                </div>
                <span className={`${Style.amount_heading} font-weight-bold mb-0 ${Style.amountDisplay}`}>
                    <small className="pr-1 text-white">{getCurrencyIcon(amount_info?.total_amount_paid?.currency)}</small>
                    <span className="text-white">{amount_info?.total_amount_paid?.value}</span>
                    <small className="text-white font-size-xl ml-2">{amount_info?.total_amount_paid?.currency}</small>
                </span>

            </div>
            <div className="p-2 text-right">
                <div>
                    <span className=" text-white-50 font-size-xs text-uppercase mb-2">
                        Amount Earned
                    </span>
                </div>
                <span className={`${Style.amount_heading} font-weight-bold mb-0 ${Style.amountDisplay}`}>
                    <small className="pr-1 text-white">{getCurrencyIcon(amount_info?.total_earned?.currency)}</small>
                    <span className="text-white">{amount_info?.total_earned?.value}</span>
                    <small className="text-white font-size-xl ml-2">{amount_info?.total_earned?.currency}</small>
                </span>
            </div>
            {/* <div className={`${Style.badge} badge badge-success px-3`}>Completed</div> */}
        </div>
    )
}

export default AmountInfoCard