/*eslint-disable*/

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { calculationMethodTypes, CARGO_CATEGORIES } from 'util/constants';
import { calcDimensionVolume } from 'util/Utils';

export const loadDisplayFCL = (cargoDetails, showIcon) => {
  if (cargoDetails) {
    const content = (
      <>
        {cargoDetails && cargoDetails.quantity && (
          <>
            <span className="">{cargoDetails.quantity}</span>
            <span className="mx-1">x</span>
          </>
        )}
        {cargoDetails &&
          cargoDetails.containerType &&
          cargoDetails.containerType.label && (
            <span className="text-truncate">
              {cargoDetails.containerType.label}
            </span>
          )}
      </>
    );

    return (
      <span className="d-flex align-items-center">
        {showIcon && cargoDetails && cargoDetails.containerType && (
          <span className="border-right border-dark pr-2 mr-2">
            <Tooltip title={cargoDetails.containerType.label} arrow>
              <img
                className="dh-15 bg-light"
                src={cargoDetails.containerType.icon}
              />
            </Tooltip>
          </span>
        )}
        <Tooltip title={content} arrow>
          <span>{content}</span>
        </Tooltip>
      </span>
    );
  }
  return null;
};

export const loadDisplayLCL = (cargoDetails) => {
  if (cargoDetails) {
    let volume;
    if (cargoDetails.packageDetails && cargoDetails.packageDetails.dimensions) {
      volume = calcDimensionVolume(cargoDetails.packageDetails.dimensions);
    }

    const content = (
      <>
        {cargoDetails && cargoDetails.quantity && (
          <>
            <span className="mr-2 pr-2 border-right border-dark">{`${
              cargoDetails.quantity
            } ${Number(cargoDetails.quantity) > 1 ? 'Units' : 'Unit'}`}</span>
          </>
        )}
        {volume && (
          <span className="text-truncate">
            {`Total: ${volume.value} `}
            <span className="text-lowercase">{`${volume.unit.label}, `}</span>
            {cargoDetails.packageDetails.weight && (
              <span>
                {`${cargoDetails.packageDetails.weight.weight} `}
                <span className="text-lowercase">
                  {cargoDetails.packageDetails.weight.unit}
                </span>
              </span>
            )}
          </span>
        )}
      </>
    );
    return (
      <span className="d-flex align-items-center">
        {cargoDetails && cargoDetails.packageType && (
          <span className="border-right border-dark pr-2 mr-2">
            <Tooltip title={cargoDetails.packageType} arrow>
              <span>
                <FontAwesomeIcon
                  icon={[
                    'fas',
                    cargoDetails.packageType === 'Pallets' ? 'pallet' : 'boxes'
                  ]}
                  className="d-20"
                />
              </span>
            </Tooltip>
          </span>
        )}
        <Tooltip title={content} arrow>
          <span>{content}</span>
        </Tooltip>
      </span>
    );
  }
  return null;
};

export const loadDisplayBulk = (cargoDetails, cargoType, showIcon) => {
  if (cargoDetails) {
    const content = (
      <>
        {cargoDetails.quantity && (
          <>
            <span className="mr-2 pr-2 border-right border-dark">{`${
              cargoDetails.quantity
            } ${Number(cargoDetails.quantity) > 1 ? 'Units' : 'Unit'}`}</span>
          </>
        )}
        {cargoDetails.volume && (
          <span className="text-truncate">
            {`Total: ${cargoDetails.volume.volume} `}
            <span className="text-lowercase">{`${cargoDetails.volume.unit}, `}</span>
            {cargoDetails.weight && (
              <span>
                {`${cargoDetails.weight.weight} `}
                <span className="text-lowercase">
                  {cargoDetails.weight.unit}
                </span>
              </span>
            )}
          </span>
        )}
      </>
    );

    return (
      <span className="d-flex align-items-center">
        {showIcon && (
          <span className="border-right border-dark pr-2 mr-2">
            <Tooltip title={cargoType.title} arrow>
              <img
                className={clsx('dh-15 bg-light', {
                  'dh-20': cargoType.value === CARGO_CATEGORIES.PKGS.value
                })}
                src={cargoType.icon}
              />
            </Tooltip>
          </span>
        )}
        <Tooltip title={content} arrow>
          <span>{content}</span>
        </Tooltip>
      </span>
    );
  }
  return null;
};

export const loadDisplayPkgs = (cargoDetails, cargoType, showIcon) => {
  let volume = cargoDetails?.details?.volume;

  if (
    cargoDetails.calculationMethod === calculationMethodTypes.dimension.value
  ) {
    const newVolume = calcDimensionVolume(cargoDetails.details.dimensions);
    volume = {
      volume: newVolume?.value,
      unit: newVolume?.unit?.value
    };
  }
  console.log('fjbhj', volume);

  if (
    cargoDetails.calculationMethod === calculationMethodTypes.dimension.value
  ) {
    const newVolume = calcDimensionVolume(cargoDetails.details.dimensions);
    volume = {
      volume: newVolume?.value,
      unit: newVolume?.unit?.value
    };
  }
  // console.log("fjbhj", volume)

  if (cargoDetails) {
    const content = (
      <>
        {cargoDetails.quantity && (
          <>
            <span className="mr-2 pr-2 border-right border-dark">{`${
              cargoDetails.quantity
            } ${Number(cargoDetails.quantity) > 1 ? 'Units' : 'Unit'}`}</span>
          </>
        )}
        {volume && (
          <span className="text-truncate">
            {`Total: ${Number(volume?.volume).toFixed(3)} `}
            <span className="text-lowercase">{`${volume.unit}, `}</span>
            {cargoDetails?.details?.weight && (
              <span>
                {`${cargoDetails?.details?.weight.weight} `}
                <span className="text-lowercase">
                  {cargoDetails?.details?.weight.unit}
                </span>
              </span>
            )}
          </span>
        )}
      </>
    );

    return (
      <span className="d-flex align-items-center">
        {showIcon && (
          <span className="border-right pr-2 mr-2 border-dark">
            <Tooltip title={cargoType.title} arrow>
              <img
                className={clsx('dh-15 bg-light', {
                  'dh-20': cargoType.value === CARGO_CATEGORIES.PKGS.value
                })}
                src={cargoType.icon}
              />
            </Tooltip>
          </span>
        )}
        <Tooltip title={content} arrow>
          <span>{content}</span>
        </Tooltip>
      </span>
    );
  }
  return null;
};

export const loadDisplayRORO = (cargoDetails, cargoType, showIcon) => {
  if (cargoDetails) {
    const content = (
      <>
        {/*  */}
        {cargoDetails.quantity && (
          <>
            <span className="mr-2 pr-2 border-right border-dark">{`${
              cargoDetails.quantity
            } ${Number(cargoDetails.quantity) > 1 ? 'Units' : 'Unit'}`}</span>
          </>
        )}
        {cargoDetails?.cargoType && (
          <span className=" mr-2 pr-2 border-right border-dark text-truncate">
            {`${cargoDetails?.cargoType?.label}`}
          </span>
        )}
        {cargoDetails?.cargoWeightRange && (
          <span className="text-truncate">
            {`${cargoDetails?.cargoWeightRange?.label}`}
          </span>
        )}
      </>
    );

    return (
      <span className="d-flex align-items-center">
        {showIcon && (
          <span className="border-right border-dark pr-2 mr-2">
            <Tooltip title={cargoType.title} arrow>
              <img
                className={clsx('dh-15', {
                  'dh-20': cargoType.value === CARGO_CATEGORIES.RORO.value
                })}
                src={cargoType.icon}
              />
            </Tooltip>
          </span>
        )}
        <Tooltip title={content} arrow>
          <span>{content}</span>
        </Tooltip>
      </span>
    );
  }
  return null;
};

export const loadDisplay = (load) => {
  if (load && load.cargoType && load.cargoDetails) {
    if (
      load.cargoType.value === CARGO_CATEGORIES.FCL.value ||
      load.cargoType.value === CARGO_CATEGORIES.FCLT.value ||
      load.cargoType.value === CARGO_CATEGORIES.FRL.value
    ) {
      return loadDisplayFCL(load.cargoDetails, true);
    } else if (
      load.cargoType.value === CARGO_CATEGORIES.LCL.value ||
      load.cargoType.value === CARGO_CATEGORIES.LTL.value
    ) {
      return loadDisplayLCL(load.cargoDetails);
    } else if (
      load.cargoType.value === CARGO_CATEGORIES.BULK.value ||
      load.cargoType.value === CARGO_CATEGORIES.FTL.value
    ) {
      return loadDisplayBulk(load.cargoDetails, load.cargoType, true);
    } else if (load.cargoType.value === CARGO_CATEGORIES.PKGS.value) {
      return loadDisplayPkgs(load.cargoDetails, load.cargoType, true);
    } else if (load.cargoType.value === CARGO_CATEGORIES.RORO.value) {
      return loadDisplayRORO(load.cargoDetails, load.cargoType, true);
    }
  }
  return null;
};

export const loadDisplayWithoutIcon = (load) => {
  if (load && load.cargoType && load.cargoDetails) {
    if (
      load.cargoType.value === CARGO_CATEGORIES.FCL.value ||
      load.cargoType.value === CARGO_CATEGORIES.FCLT.value ||
      load.cargoType.value === CARGO_CATEGORIES.FRL.value
    ) {
      return loadDisplayFCL(load.cargoDetails, false);
    } else if (
      load.cargoType.value === CARGO_CATEGORIES.LCL.value ||
      load.cargoType.value === CARGO_CATEGORIES.LTL.value
    ) {
      return loadDisplayLCL(load.cargoDetails);
    } else if (
      load.cargoType.value === CARGO_CATEGORIES.BULK.value ||
      load.cargoType.value === CARGO_CATEGORIES.FTL.value
    ) {
      return loadDisplayBulk(load.cargoDetails, load.cargoType, false);
    } else if (load.cargoType.value === CARGO_CATEGORIES.PKGS.value) {
      return loadDisplayPkgs(load.cargoDetails, load.cargoType, false);
    } else if (load.cargoType.value === CARGO_CATEGORIES.RORO.value) {
      return loadDisplayRORO(load.cargoDetails, load.cargoType, false);
    }
  }
  return null;
};

export const renderShipmentAddress = (address) => {
  return `${address?.city?.name}, ${address?.country?.code} ${
    address?.port?.code ? `| ${address?.port?.code}` : '| Unknown port'
  }`;
};
