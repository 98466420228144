import Style from './ConfirmDialog.module.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ModalSimple from 'components/ModalSimple';
import { forwardRef, useEffect, useState } from 'react';

/* eslint-disable */

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmDialog = ({
  heading = 'Please Confirm',
  question,
  confirmDialogFunction,
  confirmDialogOpen,
  setConfirmDialogOpen,
  actionButtonText = 'Confirm',
  maxWidth = 'xs',
  disabled = false
}) => {
  const handleClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirm = () => {
    confirmDialogFunction();
    handleClose();
  };

  // useEffect(() => {
  //     if (confirmDialogOpen) {
  //         document.body.style.overflow = 'hidden';
  //     }
  //     else {
  //         document.body.style.overflow = 'auto';
  //     }
  //     return () => {
  //         document.body.style.overflow = 'auto';
  //     }
  // }, [confirmDialogOpen])

  return (
    <>
      <ModalSimple
        modalTitle={heading}
        open={confirmDialogOpen}
        action={handleConfirm}
        modalDescription={question}
        maxWidth={maxWidth}
        fullWidth={true}
        handleClose={handleClose}
        actionButtonText={actionButtonText}
        disabled={disabled}></ModalSimple>
      {/* <Dialog
                open={confirmDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >


                <DialogTitle>
                    <div className="app-page-title--heading">
                        <h1 style={{ fontSize: "1rem !important" }}>{heading}</h1>
                    </div>
                </DialogTitle>


                <DialogContent style={{ padding: "15px 20px", fontSize: "14px", color: "var(--secondary-text)" }}>
                    {question}
                </DialogContent>


                <DialogActions>

                    <Button
                        variant="outlined"
                        className={Style.no_btn}
                        size="small"
                        onClick={handleClose}
                    >
                        No
                    </Button>

                    <Button
                        size="small"
                        variant="contained"
                        className="btn-primary"
                        onClick={handleConfirm}
                    >
                        Yes
                    </Button>

                </DialogActions>
            </Dialog> */}
    </>
  );
};
/* eslint-enable */
export default ConfirmDialog;
