import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import ConstantValuesService from 'service/ConstantValuesService';
import { Autocomplete } from '@material-ui/lab';
import { FormHelperText, TextField, InputLabel } from '@material-ui/core';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  dropdown: {
    position: 'relative',
    width: '100%'
  },
  errorMsg: {
    color: '#f44336',
    fontSize: '12px',
    margin: '8px 14px 0'
  },
  errorBorder: {
    border: '1px solid red'
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
});

const StateSelect = ({
  classes,
  country = undefined,
  value = undefined,
  handleChange,
  disabled = false,
  showError = false,
  error = '',
  onBlur,
  required = true,
  transportMode=""
}) => {
  const [states, setStates] = useState([]);
  const [options, setOptions] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log(country)
    if (country) {
      setLoading(true);
      ConstantValuesService.fetchStateList(country, '',transportMode).then((res) => {
        if (res.error) {
          console.log(res.error);
        } else {
          // setStates(res)
          setOptions(res);
          setLoading(false);
        }
      });
    } else {
      setOptions([]);
    }
  }, [country]);

  return (
    <>
      {loading && <LoaderComponent loading={loading} />}
      <div className={classes.root}>
        <InputLabel
          htmlFor={`State${required ? ' *' : ' (optional)'}`}></InputLabel>
        <Autocomplete
          style={{ width: '100%' }}
          size="small"
          value={value}
          id="combo-box-demo"
          // closeIcon={false}
          options={options}
          onChange={handleChange}
          getOptionLabel={(option) => option.name}
          onBlur={onBlur}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              name={'state'}
              label={`State${required ? ' *' : ' (optional)'}`}
              variant={'outlined'}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off' // new-password disable autocomplete and autofill
              }}
            />
          )}
        />
        {showError && error && (
          <FormHelperText variant="outlined" error>
            {error}
          </FormHelperText>
        )}
      </div>
    </>
  );
};

// export default StateSelect
export default compose(withStyles(styles))(StateSelect);
