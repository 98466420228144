import {
    SELECT_SHIPPER,
    SELECT_SHIPEMENT,
    ENTER_BASIC_DETAILS,
    CLEAR_SHIPMENT
} from '../actions';

const initialState = {
    shipper_id: null,
    shipper_name: null,
    shipper_cmpyId: null,
    shipment: [],
    basic_details: {}
};

export default function createShipment(state = initialState, action) {
    switch (action.type) {
        case SELECT_SHIPPER:
            return {
                ...state,
                shipper_id: action.payload.id,
                shipper_name: action.payload.name,
                shipper_cmpyId: action.payload.cmpyId
            };
        case SELECT_SHIPEMENT:
            return {
                ...state,
                shipment: action.payload
            };
        case ENTER_BASIC_DETAILS:
            return {
                ...state,
                basic_details: action.payload
            };
        case CLEAR_SHIPMENT:
            return state

        default:
            return state;
    }
}