import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { AuthService } from 'service';
import Style from './EnterPassword.module.css';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@material-ui/core';
import { Divider } from '@mui/material';
import WrapperContainer from '../../Login/WrapperContainer/WrapperContainer';
import { notify } from 'sites/shipper/components/Toast/toast';
import { useDispatch } from 'react-redux';
import CustomPasswordCheckList from 'components/PasswordCheckList';

const EnterPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [error, setError] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    const data = {
      id: id,
      password: password,
      confirmPassword: confirmPassword
    };
    AuthService.forgotPasswordEnterPassword(data).then((data) => {
      if (data.error) {
        setError(data.error);
        setTimeout(() => {
          history.push('/login');
        }, 7000);
      } else {
        history.push('/login');
        notify(
          dispatch,
          'success',
          data.message || 'Password reset successfully'
        );
      }
    });
  };

  return (
    <>
      {/* <h3>Enter Password</h3>
      <br />
      Enter New Password: <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <br />Enter Confirm Password: <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
      <br />
      {error && <p style={{ fontSize: "12px", color: "red" }}>{error}</p>}
      <button onClick={handleSubmit}>Submit</button> */}

      <WrapperContainer>
        <div className={Style.form_container}>
          <div className={Style.form}>
            <span className={Style.form_legend}>Set Your New Password</span>

            <span className={Style.form_desc}>
              Please enter your new password and confirm it to complete the
              password reset process.
            </span>

            <FormControl variant="outlined" size="small">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                autoComplete="off"
                className={Style.input}
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>

            <FormControl variant="outlined" size="small">
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                name="password"
                autoComplete="off"
                className={Style.input}
                id="outlined-adornment-password"
                type={showConfirmPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            {password && (
              <div className="mt-1">
                <CustomPasswordCheckList
                  password={password}
                  setIsValidPassword={setIsValidPassword}
                  rules={['match']}
                  valueAgain={confirmPassword}
                />
              </div>
            )}

            <div className={Style.form_actions_container}>
              {error && (
                <p style={{ fontSize: '12px', color: 'red' }}>{error}</p>
              )}
              <Button
                className={`${Style.signin_btn} btn-primary`}
                disabled={!isValidPassword}
                onClick={handleSubmit}>
                Reset
              </Button>
            </div>
          </div>

          <Divider />

          <div className={Style.caption}>
            Lost your way? No worries! We've got your back. Enter your email,
            and we'll guide you back to the right path.
          </div>
        </div>
      </WrapperContainer>
    </>
  );
};

export default EnterPassword;
