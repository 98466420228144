
/* eslint-disable */
import Style from "./from_to.module.css";
import CountryFlag from "components/CountryFlag";
import { Tooltip } from "@material-ui/core";

const screenWidth = window.innerWidth;

const FromTo = ({ origin_country_code,destination_country_code,origin,destination}) => {
    return (
        <div className="px-1 px-md-3 pt-3 bg-light w-100 rounded position-relative overflow-hidden">
            <div className={Style.origin}>

                <span className={`${Style.font_sizer} font-weight-bold text-uppercase text-black-50 mb-1`}>Place of Loading</span>

                <Tooltip title={origin}>
                    <div className={`${Style.font_sizer}`}>
                        <CountryFlag
                            size={screenWidth > 600 ? 16 : 10}
                            countryCode={origin_country_code}
                        />
                        <span className="ml-2 text-truncate">{origin}</span>
                    </div>
                </Tooltip>
            </div>

            <div className={Style.destination}>

                <span className={`${Style.font_sizer} font-weight-bold text-uppercase text-black-50 mb-1`}>Place of Discharge</span>

                <Tooltip title={destination}>
                    <div className={`${Style.font_sizer}`}>
                        <CountryFlag
                            size={screenWidth > 600 ? 16 : 10}
                            countryCode={destination_country_code}
                        />
                        <span className="ml-2 text-truncate">{destination}</span>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}
/* eslint-enable */
export default FromTo;