import {
  calculationMethodTypes,
  CARGO_CATEGORIES,
  DIMENSION_UNITS,
  VOLUME_UNITS_LIST,
  WEIGHT_UNITS
} from 'util/constants';

export const transitTimeCalculator = (time) => {
  let result = '';
  if (time?.min === time?.max) {
    result = `${time?.min} ${time?.unit}`;
  } else {
    result = `${time?.min} - ${time?.max} ${time?.unit}`;
  }
  return result;
};

export const numberToLocaleString = (num) => {
  // Convert to number with two decimal places
  const x = Number(num).toFixed(2);
  // Format using toLocaleString
  const newNum = parseFloat(x).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return newNum;
};

export const localeStringToNumber = (formattedNum) => {
  if (formattedNum) {
    const unformattedNum = parseFloat(formattedNum.replace(/,/g, ''));
    return unformattedNum;
  } else {
    return 0;
  }
};

export const getCurrencyIcon = (currency) => {
  return currencyIconList[currency?.toLowerCase()]
    ? currencyIconList[currency?.toLowerCase()]
    : currency;
};

const currencyIconList = {
  usd: '$'
};

export const calculateAirCargoTotalVolWeight = (
  cargoArray,
  calculationMethod
) => {
  try {
    if (!Array.isArray(cargoArray) || !calculationMethod) {
      throw new Error(
        'Invalid input: Please provide a valid cargo array and calculation method.'
      );
    }

    switch (calculationMethod) {
      case calculationMethodTypes.dimension.value:
        return calculateByDimensions(cargoArray);
      case calculationMethodTypes.volume.value:
        return calculateByVolume(cargoArray);
      case calculationMethodTypes.noDimensions.value:
        return calculateByNoDimensions(cargoArray);
      default:
        throw new Error(
          "Invalid calculation method: Choose 'dimensions', 'volume', or 'noDimensions'."
        );
    }
  } catch (error) {
    console.error('calculateAirCargoTotalVolWeight', error.message);
    return {};
  }
};

export const CM_TO_M = 0.01;
export const IN_TO_CM = 2.54;
export const M3_TO_KG_SEA = 1000;
export const M3_TO_KG_AIR = 166.67;
export const CFT_TO_CBM = 0.0283168;
export const defaultVolumeCBM = 0.01; // Assume a default volume for undefined packaging

export const calculateByDimensions = (cargoArray) => {
  let totalQuantity = 0;
  let totalWeight = 0;
  let totalVolume = 0;

  cargoArray.forEach((cargo) => {
    if (!cargo.dimensions || !cargo.weight || !cargo.quantity) return;

    const quantity = parseInt(cargo.quantity, 10) || 0;
    totalQuantity += quantity;

    let weight = parseFloat(cargo.weight.weight) || 0;
    if (cargo.weight.unit === WEIGHT_UNITS[0]) {
      weight *= quantity;
    }
    totalWeight += weight;

    let { length, width, height } = cargo.dimensions;
    length = parseFloat(length) || 0;
    width = parseFloat(width) || 0;
    height = parseFloat(height) || 0;

    if (cargo.dimensions.unit === DIMENSION_UNITS[1]) {
      length *= IN_TO_CM * CM_TO_M;
      width *= IN_TO_CM * CM_TO_M;
      height *= IN_TO_CM * CM_TO_M;
    } else if (cargo.dimensions.unit === DIMENSION_UNITS[0]) {
      length *= CM_TO_M;
      width *= CM_TO_M;
      height *= CM_TO_M;
    }

    const volumeCBM = length * width * height * quantity;
    totalVolume += volumeCBM;
  });

  const seaVolumetricWeight = totalVolume * M3_TO_KG_SEA;
  const airVolumetricWeight = seaVolumetricWeight / 6;

  return {
    totalQuantity,
    totalWeight: {
      unit: WEIGHT_UNITS[0],
      value: totalWeight
    },
    totalVolume: {
      unit: VOLUME_UNITS_LIST[0],
      value: totalVolume.toFixed(3)
    },
    seaVolumetricWeight: {
      unit: WEIGHT_UNITS[0],
      value: seaVolumetricWeight.toFixed(3)
    },
    airVolumetricWeight: {
      unit: WEIGHT_UNITS[0],
      value: airVolumetricWeight.toFixed(3)
    }
  };
};

export const calculateByVolume = (cargoArray) => {
  let totalQuantity = 0;
  let totalWeight = 0;
  let totalVolume = 0;

  cargoArray.forEach((cargo) => {
    if (!cargo.volume || !cargo.weight || !cargo.quantity) return;

    const quantity = parseInt(cargo.quantity, 10) || 0;
    totalQuantity += quantity;

    let weight = parseFloat(cargo.weight.weight) || 0;
    if (cargo.weight.unit === WEIGHT_UNITS[1]) {
      weight *= 0.453592;
    }
    totalWeight += weight * quantity;

    let volume = parseFloat(cargo.volume.volume) || 0;
    if (cargo.volume.unit === VOLUME_UNITS_LIST[1]) {
      volume *= CFT_TO_CBM;
    }
    totalVolume += volume * quantity;
  });

  const seaVolumetricWeight = totalVolume * M3_TO_KG_SEA;
  const airVolumetricWeight = seaVolumetricWeight / 6;

  return {
    totalQuantity,
    totalWeight: {
      unit: WEIGHT_UNITS[0],
      value: totalWeight
    },
    totalVolume: {
      unit: VOLUME_UNITS_LIST[0],
      value: totalVolume.toFixed(3)
    },
    seaVolumetricWeight: {
      unit: WEIGHT_UNITS[0],
      value: seaVolumetricWeight.toFixed(3)
    },
    airVolumetricWeight: {
      unit: WEIGHT_UNITS[0],
      value: airVolumetricWeight.toFixed(3)
    }
  };
};

export const calculateByNoDimensions = (cargoArray) => {
  let totalQuantity = 0;
  let totalWeight = 0;
  let totalVolume = 0;

  cargoArray.forEach((cargo) => {
    if (!cargo.weight || !cargo.quantity || !cargo.packaging) return;

    const quantity = parseInt(cargo.quantity, 10) || 0;
    totalQuantity += quantity;

    let weight = parseFloat(cargo.weight.weight) || 0;
    if (cargo.weight.unit === WEIGHT_UNITS[1]) {
      weight *= 0.453592;
    }
    totalWeight += weight * quantity;

    const volumeCBM = cargo.packaging.volume.value * quantity;
    totalVolume += volumeCBM;
  });

  const seaVolumetricWeight = totalVolume * M3_TO_KG_SEA;
  const airVolumetricWeight = seaVolumetricWeight / 6;

  return {
    totalQuantity,
    totalWeight: {
      unit: WEIGHT_UNITS[0],
      value: totalWeight
    },
    totalVolume: {
      unit: VOLUME_UNITS_LIST[0],
      value: totalVolume.toFixed(3)
    },
    seaVolumetricWeight: {
      unit: WEIGHT_UNITS[0],
      value: seaVolumetricWeight.toFixed(3)
    },
    airVolumetricWeight: {
      unit: WEIGHT_UNITS[0],
      value: airVolumetricWeight.toFixed(3)
    }
  };
};

export const aggregateCargoDetails = (cargoList, freightType) => {
  if (!Array.isArray(cargoList) || cargoList.length === 0) {
    return {
      totalQuantity: '0',
      aggregatedDetails: 'No cargo details available'
    };
  }

  switch (freightType) {
    case CARGO_CATEGORIES.FCL.value:
      return aggregateFCLCargoDetails(cargoList);
    case CARGO_CATEGORIES.LCL.value:
      return aggregateLCLCargoDetails(cargoList);
    case CARGO_CATEGORIES.RORO.value:
      return aggregateROROCargoDetails(cargoList);
    case CARGO_CATEGORIES.BULK.value:
      return aggregateBULKCargoDetails(cargoList);
    default:
      return {
        totalQuantity: '0',
        aggregatedDetails: 'Invalid freight type'
      };
  }
};

// FCL Aggregation function
export const aggregateFCLCargoDetails = (cargoList) => {
  // Object to store the aggregated counts for each container type
  const containerCounts = {};
  let totalQuantity = 0;

  // Iterate over each cargo item with null checks
  cargoList.forEach((cargo) => {
    if (
      !cargo ||
      !cargo.quantity ||
      !cargo.containerType ||
      !cargo.containerType.value
    ) {
      // Skip invalid or incomplete cargo entries
      return;
    }

    const quantity = parseInt(cargo.quantity, 10);

    // Validate and skip if quantity is not a valid number
    if (isNaN(quantity) || quantity <= 0) {
      return;
    }

    // Extract the container type (e.g., "20FT", "40FT")
    const containerType = cargo.containerType.value.replace('FT', `'`);

    // Add to total quantity
    totalQuantity += quantity;

    // Accumulate quantities by container type
    if (containerCounts[containerType]) {
      containerCounts[containerType] += quantity;
    } else {
      containerCounts[containerType] = quantity;
    }
  });

  // Convert the containerCounts object to the desired display format
  const aggregatedDetails =
    Object.entries(containerCounts)
      .map(([type, count]) => `${count} x ${type}`)
      .join(', ') || 'No containers available';

  return {
    totalQuantity: totalQuantity.toString(),
    aggregatedDetails: aggregatedDetails
  };
};

// LCL Aggregation function
export const aggregateLCLCargoDetails = (cargoList) => {
  let totalQuantity = 0;
  let totalWeight = 0;

  cargoList.forEach((cargo) => {
    if (!cargo || !cargo.quantity || !cargo.weight || !cargo.weight.weight) {
      return;
    }

    const quantity = parseInt(cargo.quantity, 10);
    const weight = parseFloat(cargo.weight.weight);

    if (isNaN(quantity) || quantity <= 0 || isNaN(weight) || weight <= 0) {
      return;
    }

    totalQuantity += quantity;
    totalWeight += weight * quantity;
  });

  const aggregatedDetails = `Total Boxes: ${totalQuantity}, Total Weight: ${totalWeight} KG`;

  return {
    totalQuantity: totalQuantity.toString(),
    totalWeight: `${totalWeight} KG`,
    aggregatedDetails
  };
};

// RORO Aggregation function
export const aggregateROROCargoDetails = (cargoList) => {
  let totalQuantity = 0;
  const weightClasses = {};

  cargoList.forEach((cargo) => {
    if (
      !cargo ||
      !cargo.quantity ||
      !cargo.cargoWeightRange ||
      !cargo.cargoWeightRange.description
    ) {
      return;
    }

    const quantity = parseInt(cargo.quantity, 10);
    const weightClass = cargo.cargoWeightRange.description;

    if (isNaN(quantity) || quantity <= 0) {
      return;
    }

    totalQuantity += quantity;
    weightClasses[weightClass] = (weightClasses[weightClass] || 0) + quantity;
  });

  const aggregatedDetails =
    Object.entries(weightClasses)
      .map(([classType, count]) => `${count} x ${classType}`)
      .join(' | ') || 'No vehicles available';

  return {
    totalQuantity: totalQuantity.toString(),
    aggregatedDetails
  };
};

// BULK Aggregation function
export const aggregateBULKCargoDetails = (cargoList) => {
  let totalQuantity = 0;
  let totalWeight = 0;
  let totalVolume = 0;

  cargoList.forEach((cargo) => {
    if (
      !cargo ||
      !cargo.quantity ||
      !cargo.weight ||
      !cargo.volume ||
      !cargo.weight.weight ||
      !cargo.volume.volume
    ) {
      return;
    }

    const quantity = parseInt(cargo.quantity, 10);
    const weight = parseFloat(cargo.weight.weight);
    const volume = parseFloat(cargo.volume.volume);

    if (
      isNaN(quantity) ||
      quantity <= 0 ||
      isNaN(weight) ||
      weight <= 0 ||
      isNaN(volume) ||
      volume <= 0
    ) {
      return;
    }

    totalQuantity += quantity;
    totalWeight += weight * quantity;
    totalVolume += volume * quantity;
  });

  const aggregatedDetails = `Total Weight: ${totalWeight} KG, Total Volume: ${totalVolume} CBM`;

  return {
    totalQuantity: totalQuantity.toString(),
    totalWeight: `${totalWeight} KG`,
    totalVolume: `${totalVolume} CBM`,
    aggregatedDetails
  };
};
