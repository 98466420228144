import { useState } from "react";
import { TextField, Button } from "@material-ui/core"
import { Edit } from "@mui/icons-material";
import CustomButton from "components/CustomButton/Button";
import PhoneNumberInput from "components/PhoneNumberInput";
import StyledInput from "components/StyledInput";

const EditForm = ({
  onEditClick,
  title,
  detail,
  fieldsDetail,
  onSubmit,
  editCancelClick,
  canEdit,
  disabled = false
}) => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const toggleEdit = (planDetailId, editFormId) => {
    const planDetail = document.getElementById(planDetailId);
    const editForm = document.getElementById(editFormId);
    planDetail.classList.toggle('c-hidden');
    editForm.classList.toggle('c-hidden');

    editForm.classList.contains('c-hidden')
      ? setIsFormOpen(false)
      : setIsFormOpen(true);
  };

  const handleClick = () => {
    //save the info
    toggleEdit(title, `${title}-form`);
    if (onSubmit) {
      onSubmit();
    }
  };
  /* eslint-disable */
  return (
    <>
      <div style={{ padding: "0" }} className="c-py-1">
        <div className="c-flex c-justify-content-between c-items-center">
          <span className="c-font-small">{title}</span>
          {canEdit && <>
            {isFormOpen ? (
              <CustomButton
                onClick={() => { toggleEdit(title, `${title}-form`); editCancelClick() }}
                style={{ fontSize: '0.8rem' }}>
                Cancel
              </CustomButton>
            ) : (
              <CustomButton
                onClick={() => { toggleEdit(title, `${title}-form`); onEditClick() }}
                icon={<Edit style={{ fontSize: '0.8rem' }} />}
                style={{ fontSize: '0.8rem' }}
                tooltip="Edit">
                Edit
              </CustomButton>
            )}
          </>}
        </div>
        <span id={title} className="c-font-title c-font-light">
          {detail}
        </span>

        <div id={`${title}-form`} className="c-hidden">
          {/* form  */}
          {/* <form autoComplete="off"> */}
          <div className="c-flex c-flex-wrap c-gap-1 c-mt-1">

            {fieldsDetail &&
              fieldsDetail.map((option) => (

                <> <StyledInput
                  margin="0"
                  name={option.name}
                  onChange={option.onChange}
                  value={option.value}
                  key={option.fieldLabel}
                  type={option.fieldType}
                  id="outlined-basic"
                  label={option.fieldLabel}
                  fullWidth={false}
                  error={option.error}
                  maxDate={option?.maxDate}
                />
                  {/* <TextField
                    name={option.name}
                    onChange={option.onChange}
                    value={option.value}
                    key={option.fieldLabel}
                    type={option.fieldType}
                    id="outlined-basic"
                    label={option.fieldLabel}
                    variant="outlined"
                    size="small"
                  /> */}
                </>

              ))}
          </div>

          <div className="c-mt-1">

            <Button
              size="small"
              disabled={disabled}
              className="btn-primary"
              onClick={handleClick}>
              {
                onSubmit ?
                  "Save"
                  :
                  "Done"
              }
            </Button>
          </div>
          {/* </form> */}
        </div>
      </div>
    </>
  );
};
/* eslint-enable */
export default EditForm;
